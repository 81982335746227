/* Home.css */

.home-section {
    background-color: #1a1a1d;
  }
  
  .hero-section h1 {
    color: #f8e71c;
  }
  
  .hero-section p {
    color: #d6d6d6;
  }
  
  .features-section {
    background-color: #2b2b2b;
  }
  
  .feature-card {
    border: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);
  }
  
  .about-section {
    background-color: #3d3d3d;
  }
  
  .cta-section h2 {
    color: #1a1a1d;
  }
  
  .cta-section p {
    color: #3d3d3d;
  }
  